import LadiesPrice from './LadiesPrice.vue'
import LadiesPriceCreate from './Create.vue'

export default [
    {
        path: '/ladies_price',
        name: 'LadiesPrice',
        component: LadiesPrice
    },
    {
        path: '/ladies_price/create',
        name: 'LadiesPriceCreate',
        component: LadiesPriceCreate
    },
    {
        path: '/ladies_price/edit/:id',
        name: 'LadiesPriceEdit',
        component: LadiesPriceCreate
    },
]
